.wrapper {
  width: 600px;
}

.value {
  display: flex;
}

.subUserItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.dropdownSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 14px;

  input {
    width: 230px;
  }

  button {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.dropdownItems {
  max-height: 300px;
  overflow-x: auto;
}
