.curatorField{
    min-width: 350px;
}

.curatorNameField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curatorPhoneField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curatorEmailField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.valueRow{
    display: flex;
    flex-wrap: nowrap;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.valueInput{
    min-width: 25%;
    max-width: 25%;
}