.projectCard {
    background-color: cornsilk;
}

.projectCard:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.commentCardOwner {
    background-color: rgb(25, 135, 84);
    max-width: 70%;
}

.commentCard {
    background-color: cornsilk;
    max-width: 70%;
}

.commentOwner {
    display: flex;
    justify-content: flex-end;
}

.comment {
    display: flex;
    justify-content: flex-start;
}

.projectCardFinished {
    background-color: rgb(25, 135, 84);
    color: white;
}

.cardText {
    display: flex;
    white-space: pre-wrap;
}

.pollButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}