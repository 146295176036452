.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  column-gap: 30px;
  margin-left: -50px;
}

.navList {
  display: grid;
  align-items: center;
  row-gap: 24px;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
}
