body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --toastify-color-success: #48b64a !important;
  --toastify-color-error: #dd7165 !important;
  --rt-color-error: #dd7165 !important;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 10px;
    background-color: rgba(19, 19, 19, 0.15);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(19, 19, 19, 0.25);
    border-radius: 8px;
  }
}
