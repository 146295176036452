.wrapper {
  /* position: relative; */
  padding: 0;
  display: grid;
  margin: "auto";
}

.settings {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.settings::after {
  display: none !important;
}

.settings + div {
  margin-top: 5px;
}

.settings:hover,
.settings:focus,
.settings:active {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.mapItem {
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
}

.mapItemLink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #333;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.5s ease, filter 0.5s ease;

  &:hover {
    filter: brightness(0.8);
  }
}
