.projectsPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectsGrid {
    min-width: 50%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.project {
    min-width: 350px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}

.project:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.statisticsControl {
    display: flex;
    flex-direction: column;
}
